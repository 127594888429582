/*
 * @Author: your name
 * @Date: 2021-06-16 14:28:39
 * @LastEditTime: 2021-06-21 13:47:58
 * @LastEditTime: 2021-06-18 14:05:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/api/demo.js
 */
import request from "../util/request";
import { apiPerfix } from "./config.js";

export function login(data) {
  return request({
    url: `${apiPerfix}/login`,
    method: "post",
    data,
    meta: {
      isSerialize: true,
    },
  });
}

export function uploadUserInfo(data) {
  return request({
    url: `${apiPerfix}/user/update`,
    method: "post",
    data,
  });
}

export function uploadFile(data) {
  return request({
    url: `${apiPerfix}/file/upload`,
    method: "post",
    data,
  });
}

export function getChapterList(params) {
  return request({
    url: `${apiPerfix}/chapter/list`,
    method: "get",
    params,
  });
}

export function getChapterDetail(params) {
  return request({
    url: `${apiPerfix}/chapter/getChapterDetail`,
    method: "get",
    params,
  });
}

export function addNewChapter(data) {
  return request({
    url: `${apiPerfix}/chapter/add`,
    method: "post",
    data,
  });
}

export function delNewChapter(params) {
  return request({
    url: `${apiPerfix}/chapter/del`,
    method: "get",
    params,
  });
}

export function updateChapter(data) {
  return request({
    url: `${apiPerfix}/chapter/update`,
    method: "post",
    data,
  });
}

export function getInviteList(params) {
  return request({
    url: `${apiPerfix}/invite/list`,
    method: "get",
    params,
  });
}

export function addNewInvite(data) {
  return request({
    url: `${apiPerfix}/invite/add`,
    method: "post",
    data,
  });
}

export function delNewInvite(params) {
  return request({
    url: `${apiPerfix}/invite/del`,
    method: "get",
    params,
  });
}

export function updateInviteViewCount(params) {
  return request({
    url: `${apiPerfix}/invite/update`,
    method: "get",
    params,
  });
}

export function updateInviteData(params) {
  return request({
    url: `${apiPerfix}/invite/updateData`,
    method: "get",
    params,
  });
}

export function getUserInfo() {
  return request({
    url: `${apiPerfix}/getUserInfo`,
    method: "get",
  });
}

// 视频列表
export function getVideoList() {
  return request({
    url: `${apiPerfix}/video/getList`,
    method: "get",
  });
}

export function addVideo(data) {
  return request({
    url: `${apiPerfix}/video/add`,
    method: "post",
    data,
  });
}

export function getDetailVideo(params) {
  return request({
    url: `${apiPerfix}/video/getDetail`,
    method: "get",
    params,
  });
}

export function delVideo(params) {
  return request({
    url: `${apiPerfix}/video/del`,
    method: "get",
    params,
  });
}

export function updateVideo(params) {
  return request({
    url: `${apiPerfix}/video/update`,
    method: "get",
    params,
  });
}

// 图片列表
export function getPictureList() {
  return request({
    url: `${apiPerfix}/picture/getList`,
    method: "get",
  });
}

export function addPicture(data) {
  return request({
    url: `${apiPerfix}/picture/add`,
    method: "post",
    data,
  });
}

export function getDetailPicture(params) {
  return request({
    url: `${apiPerfix}/picture/getDetail`,
    method: "get",
    params,
  });
}

export function delPicture(params) {
  return request({
    url: `${apiPerfix}/picture/del`,
    method: "get",
    params,
  });
}

export function updatePicture(params) {
  return request({
    url: `${apiPerfix}/picture/update`,
    method: "get",
    params,
  });
}

// app List

export function getAppList() {
  return request({
    url: `${apiPerfix}/app/getList`,
    method: "get",
  });
}

export function addApp(data) {
  return request({
    url: `${apiPerfix}/app/add`,
    method: "post",
    data,
  });
}

export function getDetailApp(params) {
  return request({
    url: `${apiPerfix}/app/getDetail`,
    method: "get",
    params,
  });
}

export function delApp(params) {
  return request({
    url: `${apiPerfix}/app/del`,
    method: "get",
    params,
  });
}

export function updateApp(params) {
  return request({
    url: `${apiPerfix}/app/update`,
    method: "get",
    params,
  });
}
