
import React from 'react';
import './index.scss'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect,useState} from "react";
import { uploadUserInfo } from "../../api/demo";
import UserInfo from "../components/userInfo";
import axios from 'axios'
import { apiPerfix, resourceUrl } from "../../api/config";

import {
  message,
  Button,
  Form,
  Upload,
  Input
} from 'antd';

const ProjectPage = () => {

  const [userInfo, setuserInfo] = useState({})
  const [loading, setloading] = useState(false)
  const [nowUser, setnowUser] = useState({})

  const [myform] = Form.useForm()


  useEffect(() => {
    let a = JSON.parse(localStorage.getItem('userInfo') || '{}')
    setuserInfo(a)
    setnowUser(a)
    myform.setFieldsValue(a)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

    const onFinish =(values) => {
      values.id = userInfo.id
      values.avatar = nowUser.avatar
      uploadUserInfo({
        ...values
      }).then(res => {
        setuserInfo(values)
        localStorage.setItem('userInfo', JSON.stringify(values))
      })


    }

    const beforeUpload = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error('只能上传图片!');
      }
      
      var forms = new FormData()
      var configs = {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
      forms.append('file', file)
      setloading(true)
      axios.post(`${apiPerfix}/file/upload`, forms, configs).then(res => {
        setloading(false)
        setnowUser({...nowUser, avatar: `${resourceUrl}/` + res})
      }).catch(() => {
        setloading(true)
      })
      return false;
    };







  return (
    <div className='userContainer'>
      <section className='left'>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          autoComplete="off"
          form={myform}
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action="/"
            beforeUpload={beforeUpload}
          >
            {nowUser.avatar ? (
              <img
                src={nowUser.avatar}
                alt="avatar"
                style={{
                  width: '100%',
                }}
              />
            ) : (
              uploadButton
            )}
          </Upload>

          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '请输入账号！' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="手机号"
            name="phone"
            rules={[{ required: true, message: '请输入账号！' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="账号"
            name="account"
            rules={[{ required: true, message: '请输入账号！' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[{ required: true, message: '请输入密码！' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="地址"
            name="address"
            rules={[{ required: true, message: '请输入地址！' }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label="个性签名"
            name="autograph"
            rules={[{ required: true, message: '请输入地址！' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="用户详情"
            name="userDetail"
            rules={[{ required: true, message: '请输入地址！' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
          </Form.Item>
        </Form>
      </section>
      <UserInfo userInfo={userInfo}></UserInfo>
    </div>
  );
};

export default ProjectPage;
