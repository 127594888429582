/*
 * @Author: your name
 * @Date: 2021-06-15 14:15:57
 * @LastEditTime: 2021-06-23 14:39:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /react-demo/src/components/layout/presenter.js
 */
import { useState } from "react";
import {
  FileTextOutlined,
  UserOutlined,
  PieChartOutlined,
  AndroidOutlined,
  VideoCameraAddOutlined,
  PictureOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

const usePresenter = () => {
  const [collapsed, setCollapsed] = useState(false);
  const menuList = [
    {
      name: "个人信息",
      path: "/home/projectPage",
      icon: <UserOutlined />,
    },
    {
      name: "文章列表",
      path: "/home/chapter",
      icon: <FileTextOutlined />,
    },
    {
      name: "邀请码",
      path: "/home/invite",
      icon: <LinkedinOutlined />,
    },
    {
      name: "图片列表",
      path: "/home/picture",
      icon: <PictureOutlined />,
    },
    {
      name: "视频列表",
      path: "/home/video",
      icon: <VideoCameraAddOutlined />,
    },
    {
      name: "APP列表",
      path: "/home/app",
      icon: <AndroidOutlined />,
    },
  ];

  const onCollapse = (flag) => {
    setCollapsed(flag);
  };

  return [
    {
      collapsed,
      menuList,
    },
    {
      onCollapse,
    },
  ];
};

export default usePresenter;
