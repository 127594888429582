import './index.css';
import { Layout, Menu } from 'antd';
import React, { Suspense } from 'react';
import { Route, Link } from 'react-router-dom';
import ProjectPage from '../../pages/projectPage';
import ChapterPage from '../../pages/chapter';
import InvitetPage from '../../pages/invite';
import usePresenter from './presenter';
import VideoPage from "../../pages/video";
import PicturePage from "../../pages/picture";
import AppPage from "../../pages/appList";

const { Header, Content, Sider } = Layout;
const LayoutCopotent = (props) => {
  const [states, events] = usePresenter();
  const { collapsed, menuList } = states;
  const { onCollapse } = events;

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div className="logo">博客后台</div>
        <Menu theme="dark" defaultSelectedKeys={['0']} mode="inline">
          {menuList.map((v, i) => (
            <Menu.Item key={i} icon={v.icon}>
              <Link to={v.path}>{v.name}</Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Suspense fallback="正在加载中...">
              <Route component={ProjectPage} path="/home/projectPage"></Route>
              <Route component={ChapterPage} path="/home/chapter"></Route>
              <Route component={InvitetPage} path="/home/invite"></Route>
              <Route component={VideoPage} path="/home/video"></Route>
              <Route component={PicturePage} path="/home/picture"></Route>
              <Route component={AppPage} path="/home/app"></Route>
            </Suspense>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutCopotent;
