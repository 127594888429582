import { Button, Checkbox, Form, Input, message } from 'antd';
import React from 'react';
import { useEffect } from "react";

import './index.css'
import { login } from "../../api/demo";

const Login = (props) => {

  useEffect(() => {
    let a = localStorage.getItem('userInfo')
    if(a) {
      props.history.push('/home/projectPage')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (values) => {
    login(values).then(res => {
      // 1.保存用户信息
      localStorage.setItem('userInfo', JSON.stringify(res))
      props.history.push('/home/projectPage')
      message.success("登录成功")
    })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='wrapper'>
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="账号"
        name="account"
        rules={[
          {
            required: true,
            message: '请输入姓名!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="密码"
        name="password"
        rules={[
          {
            required: true,
            message: '请输入密码!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="dashed" htmlType="submit">
          登录
        </Button>
      </Form.Item>
    </Form>
    </div>

  );
};

export default Login;