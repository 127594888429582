import React, { useEffect, useRef, useState } from 'react';
import { updateInviteData, delNewInvite, getInviteList } from "../../api/demo";
import { Space, Table, Button, Modal, message } from 'antd';
import { Form, Input } from 'antd';
import { basreUrl } from "../../api/config";

const Invite = () => {

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '邀请码',
      dataIndex: 'inviteCode',
      key: 'id'
    },
    {
      title: '访问量',
      dataIndex: 'viewCount',
      key: 'id'
    },
    {
      title: '章节id',
      dataIndex: 'chapterId',
      key: 'id'
    },
    {
      title: '代理ID',
      dataIndex: 'proxyId',
      key: 'id'
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" block onClick={() => editInvite(record)}>编辑</Button>
          <Button type="danger" block onClick={() => delInvite(record)}>删除</Button>
          <Button type="danger" block onClick={() => getHref(record)}>复制链接</Button>
        </Space>
      ),
    },
  ]

  const [chapterList, setchapterList] = useState([])
  const [isShowDialog, setisShowDialog] = useState(false)
  const [nowInfo, setnowInfo] = useState({})
  const [myform] = Form.useForm()


  const getHref =(obj) => {
    const input = document.createElement('textarea')
    input.style.cssText = 'opacity: 0;'
    var text = `${basreUrl}/#/blogDetail/${obj.chapterId}/${obj.id}/${obj.inviteCode}/${obj.proxyId}`
    input.value = text // 修改文本框的内容
    document.querySelector('body').appendChild(input)
    input.select() // 执行浏览器复制命令
    document.execCommand('copy')
    document.body.removeChild(input)

    message.success("复制成功")
  }

  const editInvite = (obj) => {
    setnowInfo(obj)
    setisShowDialog(true)
    myform.setFieldsValue(obj)
  }

  const delInvite = (obj) => {
    delNewInvite({
      id: obj.id
    }).then(res => {
      let arr = [...chapterList]
      let index = chapterList.findIndex(v => v.id === obj.id)
      arr.splice(index,1)
      setchapterList([...arr])
      message.success("删除成功")
    })
  }

  const onCancel =() => {
    setisShowDialog(false)
    
  }

  const getList = () => {
    getInviteList().then(res => {
      res.forEach(element => {
        element.key = element.id
      });
      setchapterList([...res])
    })
  }

  useEffect(() => {
    getList()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish =(vals) => {
    updateInviteData({
      ...vals,
      id: nowInfo.id
    }).then(res => {
      getList()
      setisShowDialog(false)
      message.success("更新成功")
    })
  }

  return (
    <div className="inviteContainer">
      <Table columns={columns} dataSource={chapterList} />


      <Modal title="编辑"
    onCancel={onCancel}
    footer={null}
    visible={isShowDialog}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        form={myform}
      >
        <Form.Item
          label="邀请码"
          name="inviteCode"
          rules={[{ required: true, message: '请输入邀请码!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="代理ID"
          name="proxyId"
          rules={[{ required: true, message: '请输入代理ID!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>

    </div>
  )
}

export default Invite