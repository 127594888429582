import React, { useEffect, useRef, useState } from "react";
import { Space, Table, Button, Modal, message } from "antd";
import {
  getChapterList,
  addNewChapter,
  delNewChapter,
  addNewInvite,
  updateChapter
} from "../../api/demo";
import { Form, Input } from "antd";
import "./index.scss";

// 富文本
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";

const AddInvite = (props) => {
  const onFinish = (vals) => {
    props.submit(vals);
  };

  return (
    <Modal
      title="添加邀请码"
      onCancel={props.close}
      footer={null}
      visible={props.isShowAddInvite}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="邀请码"
          name="inviteCode"
          rules={[{ required: true, message: "请输入邀请码!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="代理ID"
          name="proxyId"
          rules={[{ required: true, message: "请输入代理ID!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Chapter = () => {
  const [chapterList, setchapterList] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [htmlContent, sethtmlContent] = useState("");
  const [isShowPreview, setisShowPreview] = useState(false);
  const [isShowAddInvite, setisShowAddInvite] = useState(false);
  const [nowChapterInfo, setnowChapterInfo] = useState({});

  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "标题",
      dataIndex: "title",
      key: "id"
    },
    {
      title: "app链接",
      dataIndex: "appHref",
      key: "id",
      render: (a) =>
        a ? (
          <a href={a} target="_blank">
            {a}
          </a>
        ) : (
          "暂无链接"
        )
    },
    {
      title: "操作",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" block onClick={() => editChapter(record)}>
            编辑
          </Button>
          <Button type="danger" block onClick={() => delChapter(record)}>
            删除
          </Button>
          <Button type="danger" block onClick={() => detailChapter(record)}>
            详情
          </Button>
          <Button type="danger" block onClick={() => setInviteCode(record)}>
            生成邀请码
          </Button>
        </Space>
      )
    }
  ];

  const editChapter = (obj) => {
    setnowChapterInfo(obj);
    setisModalVisible(true);
  };

  const setInviteCode = (obj) => {
    setnowChapterInfo(obj);
    setisShowAddInvite(true);
  };

  const saveNewInviteCode = (obj) => {
    addNewInvite({
      ...obj
    }).then((res) => {
      setisShowAddInvite(false);
      message.success("新增成功");
    });
  };

  const detailChapter = (obj) => {
    setisShowPreview(true);
    sethtmlContent(obj.content);
  };

  const delChapter = (obj) => {
    delNewChapter({
      id: obj.id
    }).then((res) => {
      let arr = [...chapterList];
      let index = chapterList.findIndex((v) => v.id === obj.id);
      arr.splice(index, 1);
      setchapterList([...arr]);
      message.success("删除成功");
    });
  };

  const addDialog = () => {
    setnowChapterInfo({});
    setTimeout(() => {
      setisModalVisible(true);
    }, 0);
  };

  const cancle = () => {
    setisModalVisible(false);
  };

  const addInviteSubmit = (vals) => {
    saveNewInviteCode({
      ...vals,
      ...nowChapterInfo,
      chapterId: nowChapterInfo.id,
      viewCount: 0
    });
  };

  const closeAddInviteDialog = () => {
    setisShowAddInvite(false);
  };

  const getList = () => {
    getChapterList().then((res) => {
      if (Array.isArray(res)) {
        res.forEach((element) => {
          element.key = element.id;
        });
        setchapterList([...res]);
      }
    });
  };

  useEffect(() => {
    if (!isModalVisible) {
      getList();
      setnowChapterInfo({});
    }
  }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="chapterContainer">
      <EditCom
        cancle={cancle}
        isModalVisible={isModalVisible}
        chapterInfo={nowChapterInfo}
      ></EditCom>
      <div className="addWrapper">
        <Button type="primary" block onClick={addDialog}>
          新增
        </Button>
      </div>
      <div className="tableList">
        <Table columns={columns} dataSource={chapterList} />
      </div>

      <Modal
        title="预览详情"
        footer={null}
        visible={isShowPreview}
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div
          className=""
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        ></div>
      </Modal>
      <AddInvite
        close={closeAddInviteDialog}
        isShowAddInvite={isShowAddInvite}
        submit={addInviteSubmit}
      ></AddInvite>
    </div>
  );
};

// 编辑

const EditCom = (props) => {
  const htmlVal = useRef("");
  const [htmlStr, sethtmlStr] = useState(
    BraftEditor.createEditorState("<p></p>")
  );

  const [myform] = Form.useForm();
  myform.setFieldsValue(props.chapterInfo);

  useEffect(() => {
    sethtmlStr(BraftEditor.createEditorState(props.chapterInfo.content || ""));
  }, [props.chapterInfo.content]);

  const handleOk = () => {
    console.log("1");
  };

  const handleCancel = () => {
    props.cancle();
  };

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML();
    htmlVal.current = html;
  };

  const onFinish = (vals) => {
    // 分情况是编辑还是新增
    if (Object.keys(props.chapterInfo || {}).length > 0) {
      updateChapter({
        ...vals,
        id: props.chapterInfo.id,
        content: htmlVal.current
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("更新成功");
      });
    } else {
      addNewChapter({
        ...vals,
        content: htmlVal.current
      }).then((res) => {
        props.cancle();
        myform.setFieldsValue({
          appHref: "",
          content: ""
        });
        message.success("新增成功");
      });
    }
  };

  return (
    <Modal
      title={props.chapterInfo.title ? "编辑" : "新增文章"}
      footer={null}
      width={800}
      visible={props.isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      {props.isModalVisible ? (
        <div className="editWrapper">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            form={{ ...myform }}
          >
            <Form.Item
              label="标题"
              name="title"
              rules={[{ required: true, message: "请输入标题!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="app链接"
              name="appHref"
              rules={[{ required: true, message: "请输入app链接!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="内容" name="content">
              <div
                style={{
                  height: "0px",
                  overflow: "hidden"
                }}
              >
                {JSON.stringify(htmlStr)}
              </div>
              <BraftEditor
                style={{
                  border: "1px solid #999"
                }}
                value={htmlStr}
                onChange={handleEditorChange}
                placeholder="请输入内容"
              ></BraftEditor>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                提交
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default Chapter;
