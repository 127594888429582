import React, { useEffect, useRef, useState } from 'react';
import { Space, Table, Button, Modal,
  Upload,
  message } from 'antd';
  import { apiPerfix, resourceUrl } from "../../api/config";

import { getPictureList, addPicture, delPicture, updatePicture } from "../../api/demo";
import { Form, Input } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import axios from 'axios'
import './index.scss'
const { TextArea } = Input;



const Video = () => {

  const [chapterList, setchapterList] = useState([])
  const [isModalVisible, setisModalVisible] = useState(false)
  const [htmlContent, sethtmlContent] = useState('')
  const [isShowPreview, setisShowPreview] = useState(false)
  const [nowChapterInfo, setnowChapterInfo] = useState({})



  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: '标题',
      dataIndex: 'title',
      key: 'id'
    },
    {
      title: '链接',
      dataIndex: 'href',
      key: 'id',
      render: (a) => a ? 
      <Image
      width={200}
      src={ a }
    />
      : "暂无链接"
    },
    {
      title: '描述',
      dataIndex: 'detail',
      key: 'id',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" block onClick={ () => editChapter(record)}>编辑</Button>
          <Button type="danger" block onClick={() => delChapter(record)}>删除</Button>
          {/* <Button type="danger" block onClick={() => detailChapter(record)}>详情</Button> */}
        </Space>
      ),
    },
  ]

  // 编辑 handler
  const editChapter = (obj) => {
    setnowChapterInfo(obj)
    setisModalVisible(true)
  }

  // 详情
  const detailChapter = (obj) => {
    setisShowPreview(true)
    sethtmlContent(obj.content)
  }

  // 删除
  const delChapter = (obj) => {
    delPicture({
      id: obj.id
    }).then(res => {
      let arr = [...chapterList]
      let index = chapterList.findIndex(v => v.id === obj.id)
      arr.splice(index,1)
      setchapterList([...arr])
      message.success("删除成功")
    })
  }

  // 新增
  const addDialog = () => {
    setnowChapterInfo({})
    setTimeout(() => {
      setisModalVisible(true)
    }, 0);
  }

  // 关闭Modal
  const cancle = () => {
    setisModalVisible(false)
  }

  // 列表
  const getList = () => {
    getPictureList().then(res => {
      res.forEach(element => {
        element.key = element.id
      });
      setchapterList([...res])
    })
  }

  // 监听Modal
  useEffect(() => {
    if(!isModalVisible) {
      getList()
      setnowChapterInfo({})
    }
  }, [isModalVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  // 初始化
  useEffect(() => {
    getList()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  
  return (
    <div className="chapterContainer">
      <EditCom cancle={cancle} isModalVisible={isModalVisible} chapterInfo={nowChapterInfo}></EditCom>
      <div className='addWrapper'>
        <Button type="primary" block onClick={addDialog}>新增</Button>
      </div>
      <div className='tableList'>
          <Table columns={columns} dataSource={chapterList} />
      </div>


      <Modal title="预览详情" 
        footer={null}
        visible={isShowPreview} 
        onOk={() => setisShowPreview(false)}
        onCancel={() => setisShowPreview(false)}
      >
        <div className=''
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        >
        </div>
      </Modal>
    </div>
  )
}

// 编辑

const EditCom = (props) => {

  const htmlVal = useRef('')
  const [loading, setloading] = useState(false)

  const [nowUser, setnowUser] = useState({})

  const [myform] = Form.useForm()
  myform.setFieldsValue(props.chapterInfo)


  useEffect(() => {
    setnowUser(props.chapterInfo)
  }, [props.chapterInfo])
  

  const handleOk = () => {
    console.log('1');
  }

  const handleCancel = () => {
    props.cancle()
  }

  const handleEditorChange = (editorValue) => {
    let html = editorValue.toHTML()
    htmlVal.current = html
  }

  const onFinish =(vals) => {
    console.log('vals');
    console.log(vals);
    // 分情况是编辑还是新增
    if(Object.keys(props.chapterInfo || {}).length > 0) {
      delete vals.upload
      updatePicture({
        ...vals,
        href: nowUser.href,
        id: props.chapterInfo.id
      }).then(res => {
        props.cancle()
        myform.setFieldsValue({
          href: '',
          content: ''
        })
        message.success("更新成功")
      })
    }else {
      addPicture({
        ...vals,
        href: nowUser.href
      }).then(res => {
        props.cancle()
        myform.setFieldsValue({
          href: '',
          content: ''
        })
        message.success("新增成功")
      })
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );


  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('只能上传图片!');
    }
    
    var forms = new FormData()
    var configs = {
      headers: { 'Content-Type': 'multipart/form-data' },
    }
    forms.append('file', file)
    setloading(true)
    axios.post(`${apiPerfix}/file/upload`, forms, configs).then(res => {
      setloading(false)
      setnowUser({...nowUser, href: `${resourceUrl}/` + res})
    }).catch(() => {
      setloading(true)
    })
    return false;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <Modal title={ props.chapterInfo.title ? '编辑' :  '新增视频' } footer={null} width={800} visible={props.isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      {
        props.isModalVisible ?
        <div className='editWrapper'>
          <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
          form={{...myform}}
        >
          <Form.Item
            label="标题"
            name="title"
            rules={[{ required: true, message: '请输入标题!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="链接"
            name="upload"
            valuePropName="fileList" 
            getValueFromEvent={normFile}
          >
            <Upload
              name="href"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="/"
              beforeUpload={beforeUpload}
            >
              {nowUser.href ? (
                <img
                  src={nowUser.href}
                  alt="avatar"
                  style={{
                    width: '100%',
                  }}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="描述"
            name="detail"
          >
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
        : ''
      }
      
  </Modal>
  )
}

export default Video;